import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import styled from 'styled-components'
import { EllipseTwitterButton, MeaningTextArea, FavoriteButton, MeaningButtonList, EllipseButton } from '../UIKit'
import { animateScroll } from 'react-scroll'
import { loadWordWithMeaning, addPageview } from '../../reducks/words/operations'
import * as commonSelectors from '../../reducks/common/selectors'
import * as wordsSelectors from '../../reducks/words/selectors'
import * as meaningsSelectors from '../../reducks/meanings/selectors'
import { StoreState } from '../../store/store'
import { Helmet } from 'react-helmet'

type Props = {
  match: any
}

const backgroundColors = ['#6966ff', '#FFE271', '#71FFE5', '#FF85E4', '#FF9B63', '#A0FFA4']
const backgroundColor = backgroundColors[Math.floor(Math.random() * backgroundColors.length)]

const MeaningPage: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()
  const spelling = props.match.params.spelling as string
  const meaningId = props.match.params.meaningId as string

  const state = useSelector<StoreState, StoreState>((state) => state)
  const words = wordsSelectors.allWords(state)
  const word = words.find((word) => word.spelling === spelling)
  const isLoading = commonSelectors.isLoading(state)
  const meanings = meaningsSelectors.meaningsOf(state, spelling)
  const meaning = meaningsSelectors.meaningOf(state, spelling, meaningId)
  const [isStartedLoad, setIsLoaded] = useState(false)
  const isFinishedLoad = isStartedLoad && !isLoading

  useEffect(() => {
    if (!isStartedLoad) {
      dispatch(loadWordWithMeaning(spelling))
      dispatch(addPageview(spelling))
      setIsLoaded(true)
    }
  }, [isStartedLoad, spelling, dispatch])
  // 別の意味を選択したら画面のTOPへスクロールさせる
  useEffect(() => {
    animateScroll.scrollToTop({ duration: 300 })
  }, [meaning])

  return (
    <>
      <Helmet>
        <meta
          property="og:image"
          content={
            process.env.REACT_APP_HOST +
            '/assets/ogp/card_image.png?txt=' +
            spelling +
            '&txtalign=middle%2Ccenter&txtsize=30&s=ca549c4f8418d882443ef5c799c4c741'
          }
        ></meta>
      </Helmet>
      {word && meaning && (
        <Background>
          <MeaningTextArea word={word} meaning={meaning} bgColor={backgroundColor} />
          <TextOuter>
            <BlockText>シェアしてみんなに</BlockText>
            <BlockText>意味を教えてあげよう</BlockText>
          </TextOuter>
          <EllipseTwitterButton text="教えてあげる" content={spelling + 'の意味'} />
          <TextOuter>
            <BlockText>参考になった？</BlockText>
          </TextOuter>
          <FavoriteButton meaning={meaning} />
          <WordWrapper>
            <Word>{word.spelling}</Word>
            <InlineText>の他の意味</InlineText>
          </WordWrapper>
          <MeaningButtonList spelling={spelling} meanings={meanings} withoutIds={[meaning.id]} />
        </Background>
      )}
      {isFinishedLoad && (!word || !meaning) && (
        <Background>
          <TextOuter>
            <BlockText>言葉の意味を見つけることが</BlockText>
            <BlockText>できませんでした</BlockText>
          </TextOuter>
        </Background>
      )}
      <ButtonWrapper>
        <EllipseButton text="意味を登録する" onClick={() => dispatch(push('/word/' + spelling + '/new_meaning'))} />
      </ButtonWrapper>
    </>
  )
}

const Background = styled.div`
  text-align: center;
  color: white;
`

const WordWrapper = styled.div`
  text-align: start;
  margin: 36px 0 12px 0;
`

const TextOuter = styled.div`
  margin: 24px auto 16px auto;
`

const BlockText = styled.div`
  font-weight: bold;
`

const Word = styled.span`
  color: white;
  font-size: 32px;
  font-weight: bold;
  margin: 0 8px 0 20px;
`

const InlineText = styled.span`
  color: white;
  font-size: 16px;
  font-weight: bold;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0;
`

export default MeaningPage
