import { Action } from 'redux'
import { IMeaning } from './reducers'

export enum ActionTypes {
  LOAD_MEANINGS = 'LOAD_MEANINGS',
  ADD_FAVORITE = 'ADD_FAVORITE',
}

export const addFavoriteAction = (meaning: IMeaning): IAddFavoriteAction => {
  return {
    type: ActionTypes.ADD_FAVORITE,
    payload: {
      meaning,
    },
  }
}

export const loadMeaningsAction = (spelling: string, meanings: IMeaning[]): ILoadMeaningsAction => {
  return {
    type: ActionTypes.LOAD_MEANINGS,
    payload: {
      spelling,
      meanings,
    },
  }
}

interface IAddFavoriteAction extends Action {
  type: ActionTypes.ADD_FAVORITE
  payload: {
    meaning: IMeaning
  }
}

interface ILoadMeaningsAction extends Action {
  type: ActionTypes.LOAD_MEANINGS
  payload: {
    spelling: string
    meanings: IMeaning[]
  }
}

export type MeaningActions = ILoadMeaningsAction | IAddFavoriteAction
