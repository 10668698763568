import { StoreState } from '../../store/store'
import { createSelector } from 'reselect'
import _ from 'lodash'
import { IWord } from './reducers'
import { kanaToHira } from '../../lib/utils'

const wordsSelector = (store: StoreState) => store.words

export const allWords = createSelector([wordsSelector], (words) => words.list)
// 人気順にソート
export const wordsOrderByPageview = createSelector([wordsSelector], (words) => {
  return _.sortBy(words.list, 'pageview').reverse()
})
// 意味が追加された順にソート
export const wordsOrderByMeaningUpdatedAt = createSelector([wordsSelector], (words) => {
  const targetWords = _.reject(words.list, function (word) {
    return !word.meaning_updated_at || word.meaning_updated_at == null
  })
  return _.sortBy(targetWords, 'meaning_updated_at').reverse()
})
export const wordsBySpellingParts = (state: StoreState, spellingParts: string): IWord[] => {
  const filterBySpellingParts = createFilterBySpellingParts(state)
  return filterBySpellingParts(spellingParts)
}

const createFilterBySpellingParts = createSelector([wordsSelector], (words) =>
  _.memoize((spellingParts: string) => {
    return words.list.filter((word) => {
      return word.spelling.startsWith(spellingParts) || word.ruby.startsWith(kanaToHira(spellingParts))
    })
  })
)
