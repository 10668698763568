import { Dispatch } from 'redux'
import { push } from 'connected-react-router'
import { db, FirebaseTimestamp, FieldValue } from '../../firebase'
import { startLoadAction, finishLoadAction } from '../common/actions'
import { loadMeaningsAction, addFavoriteAction } from './actions'
import { IMeaning } from './reducers'
import { loadNgwordsIfShould } from '../ngwords/operations'
import { isIncludeNgword } from '../../lib/utils'

const wordCollection = db.collection('words')

export const loadMeanings = (spelling: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startLoadAction())
    const wordDoc = wordCollection.doc(spelling)
    await wordDoc.get().then((doc) => {
      if (!doc.exists) return dispatch(finishLoadAction())
    })
    wordDoc
      .collection('meanings')
      .get()
      .then((snapshots) => {
        let meanings: IMeaning[] = []
        snapshots.forEach((doc) => {
          const data: IMeaning = doc.data() as IMeaning
          meanings.push(data)
        })
        dispatch(loadMeaningsAction(spelling, meanings))
        dispatch(finishLoadAction())
      })
  }
}

export const addMeaning = (spelling: string, meaningContent: string) => {
  return async (dispatch: Dispatch<any>) => {
    const timestamp = FirebaseTimestamp.now()
    const currentUrl = '/word/' + spelling + '/new_meaning'

    if (meaningContent.length === 0) {
      return dispatch(push(currentUrl, { alert_messages: ['入力されていない項目があります'] }))
    }

    // ngwordチェック
    await loadNgwordsIfShould(dispatch)
    if (isIncludeNgword(meaningContent)) {
      return dispatch(push(currentUrl, { alert_messages: ['禁止ワードが含まれています'] }))
    }

    const meaning: IMeaning = {
      id: '',
      spelling,
      content: meaningContent,
      favorite: 0,
      updated_at: timestamp.toDate(),
      created_at: timestamp.toDate(),
    }

    const wordDoc = wordCollection.doc(spelling)
    wordDoc.update({
      meaning_updated_at: timestamp.toDate(),
    })
    const meaningCollection = wordDoc.collection('meanings')
    meaningCollection
      .add(meaning)
      .then((doc) => {
        doc.update({
          id: doc.id,
        })
        dispatch(push('/word/' + spelling, { success_messages: ['意味を登録しました'] }))
        dispatch(loadMeaningsAction(spelling, [meaning]))
      })
      .catch((error) => {
        throw new Error(error)
      })
  }
}

export const addFavorite = (meaning: IMeaning) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startLoadAction())
    const timestamp = FirebaseTimestamp.now()

    const wordDoc = wordCollection.doc(meaning.spelling)
    const meaningDoc = wordDoc.collection('meanings').doc(meaning.id)
    await meaningDoc
      .update({
        favorite: FieldValue.increment(1),
        updated_at: timestamp,
      })
      .then(() => (meaning.favorite += 1))
    dispatch(addFavoriteAction(meaning))
    dispatch(finishLoadAction())
  }
}
