/* eslint-disable no-undef */
import { ActionTypes, CommonActions } from './actions'

const defaultState: ICommonState = {
  isFinishedInitLoad: false,
  isLoading: false,
}

const commonReducer = (state = defaultState, action: CommonActions): ICommonState => {
  switch (action.type) {
    case ActionTypes.START_LOAD:
      return { ...state, isLoading: true }
    case ActionTypes.FINISH_LOAD:
      return { ...state, isLoading: false }
    case ActionTypes.FINISH_INIT_LOAD:
      return { ...state, isLoading: false, isFinishedInitLoad: true }
    default:
      return state
  }
}

export interface ICommonState {
  isFinishedInitLoad: boolean
  isLoading: boolean
}

export default commonReducer
