import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Twitter from '@material-ui/icons/Twitter'
import * as routerSelector from '../../reducks/router/selectors'
import { StoreState } from '../../store/store'

type Props = {
  text: string
  content: string
}

const EllipseTwitterButton: React.FC<Props> = (props: Props) => {
  const state = useSelector<StoreState, StoreState>((state) => state)
  const currentPath = routerSelector.pathname(state)
  const href =
    'https://twitter.com/intent/tweet?text=' +
    props.content +
    '&url=' +
    // 本文に表示される時自動でdecodeされてしまうので二重でエンコードをかける
    encodeURI(encodeURI(process.env.REACT_APP_HOST + currentPath))

  return (
    <a
      className="twitter-share-button"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none' }}
    >
      <StyledButton variant="contained" startIcon={<Twitter style={{ color: '#00acee' }} />}>
        <Text>{props.text}</Text>
      </StyledButton>
    </a>
  )
}

const StyledButton = styled(Button)`
  background: white;
  width: 241px;
  height: 54px;
  border-radius: 27px;
`

const Text = styled.span`
  font-weight: bold;
  font-size: 16px;
`

export default EllipseTwitterButton
