import { createSelector } from 'reselect'
import memoize from 'lodash.memoize'
import { IMeaning } from './reducers'
import { StoreState } from '../../store/store'

const meaningsSelector = (state: StoreState) => state.meanings

export const allMeanings = createSelector([meaningsSelector], (meanings) => meanings.list)

export const spelling = createSelector([meaningsSelector], (meanings) => meanings.spelling)

export const meaningOf = (state: StoreState, spelling: string, id: string): IMeaning | undefined => {
  const meanings = meaningsOf(state, spelling)
  return meanings.find((meaning) => meaning.id === id)
}

export const meaningsOf = (state: StoreState, spelling: string): IMeaning[] => {
  const filterBySpelling = createFilterBySpelling(state)
  return filterBySpelling(spelling)
}

const createFilterBySpelling = createSelector([meaningsSelector], (meanings) =>
  memoize((spelling: string) => {
    if (meanings.spelling === spelling) {
      return meanings.list
    }
    return []
  })
)
