import { createStore as reduxCreateStore, combineReducers, applyMiddleware, Store } from 'redux'
import commonReducer, { ICommonState } from '../reducks/common/reducers'
import wordReducer, { IWordState } from '../reducks/words/reducers'
import meaningReducer, { IMeaningState } from '../reducks/meanings/reducers'
import ngwordReducer, { INgwordState } from '../reducks/ngwords/reducers'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { RouterState } from 'connected-react-router'
// middlewares
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { History, createBrowserHistory } from 'history'

function createStore(history: History<ILocationState>): Store<StoreState> {
  let middlewares = []
  middlewares.push(routerMiddleware(history))
  middlewares.push(thunk)
  if (process.env.NODE_ENV === `development` || process.env.REACT_APP_ENV === `development`) {
    middlewares.push(createLogger())
  }

  return reduxCreateStore(
    combineReducers<StoreState>({
      router: connectRouter(history),
      common: commonReducer,
      words: wordReducer,
      ngwords: ngwordReducer,
      meanings: meaningReducer,
    }),
    applyMiddleware(...middlewares)
  )
}

export type StoreState = {
  router: RouterState<ILocationState>
  common: ICommonState
  words: IWordState
  ngwords: INgwordState
  meanings: IMeaningState
}

interface ILocationState {
  success_messages: string[]
  alert_messages: string[]
  info_messages: string[]
  error_messages: string[]
}

export const history = createBrowserHistory<ILocationState>()
export const store = createStore(history)
