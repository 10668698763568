import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { EllipseButton } from '.'
import { addMeaning } from '../../reducks/meanings/operations'

type Props = {
  spelling: string
}

const MeaningRegistration: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()

  const [meaningContent, setMeaningContent] = useState('')
  return (
    <Background>
      <FormWrapper>
        <Text>言葉</Text>
        <Word>{props.spelling}</Word>
        <Text>意味</Text>
        <StyledTextField
          placeholder=""
          multiline={true}
          rows={5}
          variant="outlined"
          required={true}
          onChange={(event) => setMeaningContent(event.target.value)}
        />
        <Spacer height={16} />
        <EllipseButton text="登録する" onClick={() => dispatch(addMeaning(props.spelling, meaningContent))} />
      </FormWrapper>
    </Background>
  )
}

const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 10px 0;
  background: #404040;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 375px;
  margin: 1rem 0;
`

const Text = styled.div`
  align-self: flex-start;
  font-weight: bold;
  margin: 10px 0 7px 0;
`

const Word = styled.div`
  align-self: flex-start;
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 0 10px;
`

const Spacer = styled.div<{ height: number }>`
  margin-bottom: ${({ height }) => height}px;
`

const StyledTextField = styled(TextField)`
  background: white;
  /* height: 54px; */
  width: 343px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`

export default MeaningRegistration
