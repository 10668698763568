import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import styled from 'styled-components'
import { MeaningButtonList, EllipseButton } from '../UIKit'
import { loadWordWithMeaning, addPageview } from '../../reducks/words/operations'
import * as commonSelectors from '../../reducks/common/selectors'
import * as wordsSelectors from '../../reducks/words/selectors'
import * as meaningsSelectors from '../../reducks/meanings/selectors'
import { StoreState } from '../../store/store'

type Props = {
  match: any
}

const WordPage: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()
  const state = useSelector<StoreState, StoreState>((state) => state)
  /** URLから取得したスペル */
  const spelling = props.match.params.spelling as string
  const isLoading = commonSelectors.isLoading(state)
  const words = wordsSelectors.allWords(state)
  const word = words.find((word) => word.spelling === spelling)
  const meanings = meaningsSelectors.meaningsOf(state, spelling)

  const [isStartedLoad, setIsStartedLoad] = useState(false)
  const isFinishedLoad = isStartedLoad && !isLoading

  useEffect(() => {
    if (!isStartedLoad) {
      dispatch(loadWordWithMeaning(spelling))
      dispatch(addPageview(spelling))
      setIsStartedLoad(true)
    }
  }, [isStartedLoad, spelling, dispatch])

  return (
    <div>
      {word && (
        <>
          <TitleWrapper>
            <Title>
              {word.spelling}
              <Text>の意味</Text>
            </Title>
          </TitleWrapper>
          {meanings.length === 0 && <Text>言葉の意味が登録されていません</Text>}
          <MeaningButtonList spelling={spelling} meanings={meanings} />
        </>
      )}
      {isFinishedLoad && !word && <Text>単語が見つかりませんでした</Text>}
      <ButtonWrapper>
        <EllipseButton text="意味を登録する" onClick={() => dispatch(push('/word/' + spelling + '/new_meaning'))} />
      </ButtonWrapper>
    </div>
  )
}

const Title = styled.h1`
  color: white;
  font-size: 32px;
  font-weight: bold;
  margin: 0 8px 0 20px;
`

const Text = styled.span`
  color: white;
  font-size: 16px;
  font-weight: bold;
`

const TitleWrapper = styled.div`
  margin: 12px 0;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0;
`

export default WordPage
