import { Dispatch } from 'redux'
import { db } from '../../firebase'
import { loadNgwordsAction } from './actions'
import { store } from '../../store/store'

const ngwordsDocRef = db.collection('ngwords').doc('default')
type NgwordData = {
  contents: string[]
}

export const loadNgwordsIfShould = async (dispatch: Dispatch<any>) => {
  if (store.getState().ngwords.list.length >= 1) return
  const doc = await ngwordsDocRef.get()
  if (doc.exists) {
    const ngwordData = doc.data() as NgwordData
    dispatch(loadNgwordsAction(ngwordData.contents))
  }
}
