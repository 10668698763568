import React from 'react'
import styled from 'styled-components'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import { IWord } from '../../reducks/words/reducers'
import { IMeaning } from '../../reducks/meanings/reducers'

type Props = {
  word: IWord
  meaning: IMeaning
  bgColor: string
}

const MeaningTextArea: React.FC<Props> = (props: Props) => {
  return (
    <Rect bgcolor={props.bgColor}>
      <RectInner>
        <div>
          <Spelling>{props.word.spelling}</Spelling>
          <Ruby>【{props.word.ruby}】</Ruby>
        </div>
        <Meaning>{props.meaning.content}</Meaning>
      </RectInner>
      <StyledSvgLoader path="/assets/logo.svg" width="45" height="23">
        <SvgProxy selector="path" fill="black" />
      </StyledSvgLoader>
    </Rect>
  )
}

const StyledSvgLoader = styled(SvgLoader)`
  position: absolute;
  bottom: 8px;
  right: 8px;
`

const Rect = styled.div<{ bgcolor: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 91.5%;
  color: black;
  background: ${({ bgcolor }) => bgcolor};
  text-align: start;
  margin: 0 auto;
  padding: 30px 0;
  align-items: baseline;
`

const RectInner = styled.div`
  margin: 0 16px;
`

const Spelling = styled.span`
  font-size: 32px;
  font-weight: bold;
`

const Ruby = styled.span`
  font-size: 16px;
  font-weight: bold;
`

const Meaning = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 12px;
`

export default MeaningTextArea
