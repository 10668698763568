import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { scroller } from 'react-scroll'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import * as routerSelector from '../../reducks/router/selectors'
import { StoreState } from '../../store/store'
import Button from '@material-ui/core/Button'
import { Theme } from '@material-ui/core/styles'

type Props = {
  initial: string
}

const initialSearchPagePath = '/initial_search'

const InitialButton: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()
  const state = useSelector<StoreState, StoreState>((state) => state)
  const currentPath = routerSelector.pathname(state)

  const linkOrScroll = (currentPath: string) => {
    if (currentPath === initialSearchPagePath) {
      scroller.scrollTo(props.initial, {
        duration: 600,
        delay: 0,
        smooth: 'easeInOutQuart',
      })
    } else {
      dispatch(push(initialSearchPagePath + '#' + props.initial))
    }
  }

  return (
    <StyledButton variant="contained" color="secondary" onClick={() => linkOrScroll(currentPath)}>
      <Word>{props.initial}</Word>
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-wrap: break-word;
  margin: 5px;
  cursor: pointer;
`

const Word = styled.span<{ theme: Theme }>`
  font-weight: bold;
  font-size: 20px;
  color: ${({ theme }) => theme.palette.primary.main};
`

export default InitialButton
