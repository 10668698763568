import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Favorite from '@material-ui/icons/Favorite'
import { addFavorite } from '../../reducks/meanings/operations'
import { IMeaning } from '../../reducks/meanings/reducers'

type Props = {
  meaning: IMeaning
}

const FavoriteButton: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()

  return (
    <StyledButton variant="outlined" onClick={() => dispatch(addFavorite(props.meaning))}>
      <StyledFavorite />
      <Text>{props.meaning.favorite.toLocaleString()}</Text>
    </StyledButton>
  )
}

const StyledFavorite = styled(Favorite)`
  font-size: 16px;
  color: #ff73ff;
  margin-right: 12px;
`

const StyledButton = styled(Button)`
  border: 1px solid white;
  width: 196px;
  height: 61px;
  margin: 0 10px;
`

const Text = styled.span`
  color: white;
  font-weight: bold;
  font-size: 16px;
`

export default FavoriteButton
