import React from 'react'
import styled from 'styled-components'
import { WordLinkButton } from '.'
import { IWord } from '../../reducks/words/reducers'

type Props = {
  words: IWord[]
  withSort?: boolean
}

const WordLinkButtonList: React.FC<Props> = ({ words, withSort = false }: Props) => {
  // 辞書順でソート
  if (withSort) {
    words = words.sort((w1: IWord, w2: IWord) => (w1.ruby > w2.ruby ? 1 : -1))
  }

  return (
    <Background>
      {words.map((word: IWord) => {
        return <WordLinkButton key={word.spelling} word={word} />
      })}
    </Background>
  )
}

const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 10px 0;
  background: #404040;
`

export default WordLinkButtonList
