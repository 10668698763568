import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { EllipseButton } from '.'
import { addWord } from '../../reducks/words/operations'

type Props = {
  defaultSpelling?: string
}

const WordRegistration: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()
  const defaultSpelling = props.defaultSpelling || ''
  const [spelling, setSpelling] = useState(defaultSpelling)
  const [ruby, setRuby] = useState('')

  return (
    <Background>
      <FormWrapper>
        <Text>登録する言葉</Text>
        <StyledTextField
          defaultValue={defaultSpelling}
          placeholder="タピオカ"
          variant="outlined"
          required={true}
          onChange={(event) => setSpelling(event.target.value)}
        />
        <Text>読み方</Text>
        <StyledTextField
          placeholder="たぴおか"
          variant="outlined"
          required={true}
          onChange={(event) => setRuby(event.target.value)}
        />
        <Spacer height={16} />
        <EllipseButton text="登録する" onClick={() => dispatch(addWord(spelling, ruby))} />
      </FormWrapper>
    </Background>
  )
}

const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 10px 0;
  background: #404040;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 375px;
  margin: 1rem 0;
`

const Text = styled.div`
  align-self: flex-start;
  font-weight: bold;
  margin: 10px 0 7px 0;
`

const Spacer = styled.div<{ height: number }>`
  margin-bottom: ${({ height }) => height}px;
`

const StyledTextField = styled(TextField)`
  background: white;
  height: 54px;
  width: 343px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`

export default WordRegistration
