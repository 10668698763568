import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { WordRegistration } from '../UIKit'
import * as routerSelector from '../../reducks/router/selectors'
import { StoreState } from '../../store/store'

const NewWordPage: React.FC = () => {
  const state = useSelector<StoreState, StoreState>((state) => state)
  const spelling = decodeURI(routerSelector.query(state)['spelling'] || '')

  return (
    <Background>
      <TextTitle>言葉の登録</TextTitle>
      <Text>登録してみんなに教えてあげよう</Text>
      <WordRegistration defaultSpelling={spelling} />
    </Background>
  )
}

const Background = styled.div`
  text-align: center;
  color: white;
`

const TextTitle = styled.h2`
  margin-bottom: 10px;
`

const Text = styled.div`
  font-weight: bold;
  margin-bottom: 1rem;
`

export default NewWordPage
