import { ActionTypes, NgwordActions } from './actions'

export interface INgwordState {
  list: string[]
}

const defaultState: INgwordState = { list: [] }

const ngwordReducer = (state = defaultState, action: NgwordActions): INgwordState => {
  switch (action.type) {
    case ActionTypes.LOAD_NGWORDS:
      return { ...state, list: action.payload.ngwords }
    default:
      return state
  }
}

export default ngwordReducer
