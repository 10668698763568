import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

const Header: React.FC = () => {
  const dispatch = useDispatch()

  return (
    <Background>
      <Logo src="/assets/logo.svg" alt="logo" onClick={() => dispatch(push('/'))} />
      <TextArea>
        <Text>ウチらでつくる大辞典</Text>
      </TextArea>
    </Background>
  )
}

const Background = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 100px;
`

const Logo = styled.img`
  margin-left: 5vw;
  cursor: pointer;
`

const TextArea = styled.div`
  margin-left: 7px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
`

const Text = styled.div`
  color: white;
  font-size: 12px;
  font-weight: bold;
`

export default Header
