import { Dispatch } from 'redux'
import { db, FirebaseTimestamp, FieldValue } from '../../firebase'
import { startLoadAction, finishLoadAction, finishInitLoadAction } from '../common/actions'
import { loadMeaningsAction } from '../meanings/actions'
import { loadNgwordsIfShould } from '../ngwords/operations'
import { IMeaning } from '../meanings/reducers'
import { loadWordsAction } from './actions'
import { IWord } from './reducers'
import { push } from 'connected-react-router'
import { isHiragana, kanaToHira, isIncludeNgword } from '../../lib/utils'

import _ from 'lodash'

const wordCollection = db.collection('words')
const initWordNum = 500

export const loadInitWords = () => {
  return (dispatch: Dispatch<any>) => {
    let words: IWord[] = []
    dispatch(startLoadAction())
    const query = wordCollection.orderBy('pageview').limit(initWordNum)
    query.get().then((snapshots) => {
      snapshots.forEach((doc) => {
        const data: IWord = doc.data() as IWord
        words.push(data)
      })
      dispatch(loadWordsAction(words))
      dispatch(finishInitLoadAction())
    })
  }
}

// 検索フォームからの単語検索
export const loadWordsBySearchForm = (spellingParts: string) => {
  return async (dispatch: Dispatch<any>) => {
    let words: IWord[] = []
    const appendWords = async (field: string, cursor: string) => {
      const query = wordCollection
        .orderBy(field)
        .startAt(cursor)
        .endAt(cursor + '\uf8ff')
        .limit(500)
      await query.get().then((snapshots) => {
        snapshots.forEach((doc) => {
          const data: IWord = doc.data() as IWord
          words.push(data)
        })
      })
    }
    dispatch(startLoadAction())
    await appendWords('spelling', spellingParts)
    await appendWords('ruby', kanaToHira(spellingParts))
    words = _.uniqBy(words, 'spelling')

    dispatch(loadWordsAction(words))
    dispatch(finishLoadAction())
  }
}

export const loadWordWithMeaning = (spelling: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startLoadAction())
    const wordDoc = wordCollection.doc(spelling)
    await wordDoc.get().then((doc) => {
      if (!doc.exists) return dispatch(finishLoadAction())
      const word: IWord = doc.data() as IWord
      dispatch(loadWordsAction([word]))
    })
    wordDoc
      .collection('meanings')
      .get()
      .then((snapshots) => {
        let meanings: IMeaning[] = []
        snapshots.forEach((doc) => {
          const data: IMeaning = doc.data() as IMeaning
          meanings.push(data)
        })
        dispatch(loadMeaningsAction(spelling, meanings))
        dispatch(finishLoadAction())
      })
  }
}

export const addPageview = (spelling: string) => {
  return () => {
    const wordDoc = wordCollection.doc(spelling)
    wordDoc.get().then((doc) => {
      if (!doc.exists) return
      wordDoc.update({
        pageview: FieldValue.increment(1),
      })
    })
  }
}

export const addWord = (spelling: string, ruby: string) => {
  return async (dispatch: Dispatch<any>) => {
    const timestamp = FirebaseTimestamp.now()
    const currentUrl = '/new_word'

    if (spelling.length === 0 || ruby.length === 0) {
      return dispatch(push('new_word', { alert_messages: ['入力されていない項目があります'] }))
    }

    if (!isHiragana(ruby)) {
      return dispatch(push('new_word', { alert_messages: ['読み方はひらがなで入力してください'] }))
    }

    const isWordExist = await wordCollection
      .doc(spelling)
      .get()
      .then((doc) => {
        return doc.exists
      })

    // ngwordチェック
    await loadNgwordsIfShould(dispatch)
    if (isIncludeNgword(spelling)) {
      return dispatch(push(currentUrl, { alert_messages: ['禁止ワードが含まれています'] }))
    }

    if (isWordExist) {
      return dispatch(push(currentUrl, { alert_messages: ['すでに登録されている言葉です'] }))
    }

    const data: IWord = {
      spelling,
      ruby,
      pageview: 0,
      meaning_updated_at: null,
      updated_at: timestamp.toDate(),
      created_at: timestamp.toDate(),
    }

    return wordCollection
      .doc(spelling)
      .set(data)
      .then(() => {
        dispatch(loadWordsAction([data]))
        dispatch(push('/word/' + spelling + '/new_meaning', { success_messages: ['言葉を登録しました'] }))
      })
      .catch((error) => {
        throw new Error(error)
      })
  }
}
