import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { InitialButtonList, InitialLine, WordLinkButtonList, SearchTextArea } from '../UIKit'
import { initials, vowel } from '../../lib/utils'
import { loadInitWords } from '../../reducks/words/operations'
import { scroller } from 'react-scroll'
import { IWord } from '../../reducks/words/reducers'
import * as wordsSelectors from '../../reducks/words/selectors'
import * as commonSelectors from '../../reducks/common/selectors'
import * as routerSelector from '../../reducks/router/selectors'
import { StoreState } from '../../store/store'

const InitialSearchPage: React.FC = () => {
  const dispatch = useDispatch()
  const state = useSelector<StoreState, StoreState>((state) => state)
  const words = wordsSelectors.allWords(state)
  const shouldInitLoad = commonSelectors.shouldInitLoad(state)

  useEffect(() => {
    if (shouldInitLoad) {
      dispatch(loadInitWords())
    }
    return () => {}
  }, [shouldInitLoad, dispatch])

  /** 画面遷移時のスクロール処理 */
  const hashInitial = decodeURI(routerSelector.urlHash(state)).replace('#', '')
  useEffect(() => {
    if (initials.includes(hashInitial)) scroller.scrollTo(hashInitial, {})
  }, [hashInitial])

  return (
    <Background>
      <SearchTextArea />
      <TextTitle>言葉一覧</TextTitle>
      <Text>読み方の頭文字から探せるよ</Text>
      <InitialButtonList />
      {initials.map((initial) => {
        const filteredWords: IWord[] = words.filter((word) => {
          return vowel(word.ruby[0]) === initial
        })
        return [
          <InitialLine key={'InitialLine' + initial} initial={initial} />,
          <WordLinkButtonList key={'WordLinkButtonList/' + initial} words={filteredWords} withSort={true} />,
        ]
      })}
    </Background>
  )
}

const Background = styled.div`
  text-align: center;
  color: white;
`

const TextTitle = styled.h2`
  margin-bottom: 10px;
`

const Text = styled.div`
  font-weight: bold;
`

export default InitialSearchPage
