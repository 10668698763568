import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme({
  palette: {
    primary: {
      // ピンク
      main: '#ff73ff',
    },
    secondary: {
      main: '#404040',
      dark: '#3b3b3b',
    },
    text: {
      primary: '#000',
    },
    background: {
      default: '#2d2d2d',
    },
  },
  shape: { borderRadius: 4 },
})

export default theme
