import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'

type Props = {
  text: string
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void
}

const EllipseButton: React.FC<Props> = (props: Props) => {
  return (
    <StyledButton variant="contained" color="primary" onClick={(event: any) => props.onClick(event)}>
      <Text>{props.text}</Text>
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  width: 241px;
  height: 54px;
  border-radius: 27px;
`

const Text = styled.span`
  font-weight: bold;
  font-size: 16px;
`

export default EllipseButton
