import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { IWord } from '../../reducks/words/reducers'
import { Theme } from '@material-ui/core/styles'

type Props = {
  word: IWord
}

const WordLinkButton: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()

  return (
    <StyledButton onClick={() => dispatch(push('/word/' + props.word.spelling))}>
      <Word>{props.word.spelling}</Word>
      <WordRuby>{props.word.ruby}</WordRuby>
      <Triangle />
    </StyledButton>
  )
}

const StyledButton = styled.div`
  display: flex;
  position: relative;
  height: 70px;
  width: 91.5%;
  background: #2d2d2d;
  border-radius: 8px;
  text-align: start;
  margin: 4px 0;
  cursor: pointer;
`

const Word = styled.div`
  color: white;
  position: absolute;
  font-weight: bold;
  text-decoration: underline;
  top: 17.14%;
  left: 4.7%;
`

const WordRuby = styled.div<{ theme: Theme }>`
  position: absolute;
  font-size: 13px;
  top: 57.1%;
  left: 4.7%;
  color: #999;
`

const Triangle = styled.div<{ theme: Theme }>`
  position: absolute;
  right: 5%;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 13px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 2px;
  align-self: center;
`

export default WordLinkButton
