import React from 'react'
import { useSelector } from 'react-redux'
import Alert from '@material-ui/lab/Alert'
import * as routerSelector from '../../reducks/router/selectors'
import { StoreState } from '../../store/store'

const FlashMessages: React.FC = () => {
  const state = useSelector<StoreState, StoreState>((state) => state)
  const locationState = routerSelector.locationState(state)
  const success_messages = locationState.success_messages || []
  const alertMessages = locationState.alert_messages || []

  return (
    <div>
      {success_messages.map((success_message: string, i: number) => {
        return (
          <Alert severity="success" key={i}>
            {success_message}
          </Alert>
        )
      })}
      {alertMessages.map((alertMessage: string, i: number) => {
        return (
          <Alert severity="warning" key={i}>
            {alertMessage}
          </Alert>
        )
      })}
    </div>
  )
}

export default FlashMessages
