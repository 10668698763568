import { Action } from 'redux'
import { IWord } from './reducers'

export enum ActionTypes {
  LOAD_WORDS = 'LOAD_WORDS',
}

export const loadWordsAction = (words: IWord[]): ILoadWordsAction => {
  return {
    type: ActionTypes.LOAD_WORDS,
    payload: {
      words,
    },
  }
}

interface ILoadWordsAction extends Action {
  type: ActionTypes.LOAD_WORDS
  payload: {
    words: IWord[]
  }
}

export type WordActions = ILoadWordsAction
