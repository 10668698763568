import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { SvgIconTypeMap } from '@material-ui/core'
import { IconText } from '.'
import { push } from 'connected-react-router'

type Props = {
  svgIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  text: string
  path: string
}

const IconTextLinkButton: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()

  return (
    <>
      {props.path.startsWith('https://') ? (
        <a href={props.path} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <IconText svgIcon={props.svgIcon} text={props.text} />
        </a>
      ) : (
        <ButtonCursor onClick={() => dispatch(push(props.path))}>
          <IconText svgIcon={props.svgIcon} text={props.text} />
        </ButtonCursor>
      )}
    </>
  )
}

const ButtonCursor = styled.div`
  cursor: pointer;
`

export default IconTextLinkButton
