import { ActionTypes, WordActions } from './actions'

export interface IWord {
  spelling: string
  ruby: string
  pageview: number
  meaning_updated_at: Date | null
  updated_at: Date
  created_at: Date
}

export interface IWordState {
  list: IWord[]
}

const defaultState: IWordState = { list: [] }

const wordReducer = (state = defaultState, action: WordActions) => {
  switch (action.type) {
    case ActionTypes.LOAD_WORDS:
      return {
        // 読み込んだワードはstoreに追加していく
        list: state.list
          .filter((word) => {
            return !action.payload.words.map((word) => word.spelling).includes(word.spelling)
          })
          .concat(action.payload.words),
      }
    default:
      return state
  }
}

export default wordReducer
