import React from 'react'
import styled from 'styled-components'

type Props = {
  initial: string
}

const InitialLine: React.FC<Props> = (props: Props) => {
  return (
    <Background>
      <Initial id={props.initial}>{props.initial}</Initial>行
    </Background>
  )
}

const Background = styled.div`
  text-align: left;
  padding: 20px 4.3%;
  background: #2d2d2d;
`

const Initial = styled.span`
  font-size: 32px;
  color: white;
  font-weight: bold;
  margin-right: 3px;
`

export default InitialLine
