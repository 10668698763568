import React from 'react'
import styled from 'styled-components'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { IMeaning } from '../../reducks/meanings/reducers'

type Props = {
  spelling: string
  meaning: IMeaning
}

const MeaningButton: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()

  return (
    <StyledButton onClick={() => dispatch(push('/word/' + props.spelling + '/meaning/' + props.meaning.id))}>
      <TextWrapper>
        <Text>{props.meaning.content}</Text>
      </TextWrapper>
      <FavoriteWrapper>
        <FavoriteIcon htmlColor="#FF73FF" style={{ fontSize: '16px' }} />
        <FavoriteCount>{numWithUnit(props.meaning.favorite)}</FavoriteCount>
      </FavoriteWrapper>
    </StyledButton>
  )
}

const numWithUnit = (num: number) => {
  if (num < 10_000) {
    return num.toLocaleString()
  }
  if (num < 100_000) {
    return (num / 10_000).toFixed(1) + '万'
  }
  if (num < 100_000_000) {
    return (num / 10_000).toFixed(0) + '万'
  }
  return (num / 100_000_000).toFixed(0) + '億'
}

const StyledButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  width: 91.5%;
  height: 70px;
  background: #2d2d2d;
  border-radius: 8px;
  text-align: start;
  margin: 4px 0;
  cursor: pointer;
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 83%;
`

const Text = styled.span`
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin: 14px 10px 13px 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`

const FavoriteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-basis: ${100 - 83}%;
  align-items: center;
`

const FavoriteCount = styled.span`
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 8px;
  word-break: keep-all;
`

export default MeaningButton
