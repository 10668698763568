import { store } from '../store/store'
// 頭文字リスト
export const initials = ['あ', 'か', 'さ', 'た', 'な', 'は', 'ま', 'や', 'ら', 'わ']

// 受け取った1文字の母音を返す
export const vowel = (char: string) => {
  switch (true) {
    case /[あいうえおぁぃぅぇぉ]/.test(char):
      return 'あ'
    case /[かきくけこがぎぐげご]/.test(char):
      return 'か'
    case /[さしすせそざじずぜぞ]/.test(char):
      return 'さ'
    case /[たちつてとだぢづでどっ]/.test(char):
      return 'た'
    case /[なにぬねの]/.test(char):
      return 'な'
    case /[はひふへほばびぶべぼぱぴぷぺぽ]/.test(char):
      return 'は'
    case /[まみむめも]/.test(char):
      return 'ま'
    case /[やゆよゃゅょ]/.test(char):
      return 'や'
    case /[らりるれろ]/.test(char):
      return 'ら'
    case /[わをん]/.test(char):
      return 'わ'
    default:
      return ''
  }
}

export const isHiragana = (str: string) => {
  str = str === null ? '' : str
  return str.match(/^[ぁ-んー]+$/) ? true : false
}

export const kanaToHira = (str: string) => {
  return str.replace(/[\u30a1-\u30f6]/g, (match) => {
    const chr = match.charCodeAt(0) - 0x60
    return String.fromCharCode(chr)
  })
}

export const isIncludeNgword = (str: string) => {
  const ngwords = store.getState().ngwords.list
  return ngwords.some((ngword) => kanaToHira(str).includes(ngword))
}
