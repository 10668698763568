import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import Input from '@material-ui/core/Input'
import SearchIcon from '@material-ui/icons/Search'
import Button from '@material-ui/core/Button'
import { push } from 'connected-react-router'
import { useForm } from 'react-hook-form'
import { loadWordsBySearchForm } from '../../reducks/words/operations'

type FormData = {
  spellingParts: string
}

const SearchTextArea: React.FC = () => {
  const dispatch = useDispatch()
  const { register, handleSubmit, errors } = useForm<FormData>()
  const onSubmit = handleSubmit(({ spellingParts }) => {
    dispatch(loadWordsBySearchForm(spellingParts))
    dispatch(push('/search_result/' + spellingParts))
  })

  return (
    <StyledForm onSubmit={onSubmit}>
      <FormInner>
        <StyledInput
          name="spellingParts"
          placeholder="気になるキーワードで検索"
          disableUnderline={true}
          inputRef={register({ required: true })}
        />
        <StyledButton variant="contained" color="secondary" onClick={onSubmit}>
          <StyledSearchIcon color="primary" />
        </StyledButton>
      </FormInner>
      {errors.spellingParts && <span>検索するワードを入力してください</span>}
    </StyledForm>
  )
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0;
`

const FormInner = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
  background: white;
  height: 54px;
  width: 343px;
  border-radius: 10px;
`

const StyledInput = styled(Input)`
  margin-left: 1rem;
  height: 100%;
  width: 260px;
  font-size: 16px;
  font-weight: bold;
`

const StyledButton = styled(Button)`
  display: flex;
  align-self: center;
  margin-right: 5px;
  height: 46px;
  width: 46px;
  min-width: 46px;
  border-radius: 8px;
`

const StyledSearchIcon = styled(SearchIcon)`
  align-self: center;
  font-weight: bold;
  width: 32px;
  height: 32px;
`

export default SearchTextArea
