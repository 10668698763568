import { createSelector } from 'reselect'
import { StoreState } from '../../store/store'

const commonSelector = (state: StoreState) => state.common

export const isLoading = createSelector([commonSelector], (common) => common.isLoading)
export const shouldInitLoad = createSelector(
  [commonSelector],
  (common) => !common.isFinishedInitLoad && !common.isLoading
)
