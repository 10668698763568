import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { SearchTextArea, SearchResultWords } from '../UIKit'
import * as wordsSelectors from '../../reducks/words/selectors'
import { StoreState } from '../../store/store'

type Props = {
  match: any
}

const SearchResultPage: React.FC<Props> = (props: Props) => {
  /** URLから取得したスペル */
  const spellingParts = props.match.params.spelling_parts as string
  const state = useSelector<StoreState, StoreState>((state) => state)
  const words = wordsSelectors.wordsBySpellingParts(state, spellingParts)

  return (
    <Background>
      <SearchTextArea />
      <SearchResultWords words={words} spellingParts={spellingParts} />
    </Background>
  )
}

const Background = styled.div`
  text-align: center;
  color: white;
`

export default SearchResultPage
