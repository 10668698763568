import React from 'react'
import styled from 'styled-components'
import { MeaningRegistration } from '../UIKit'

type Props = {
  match: any
}

const NewMeaningPage: React.FC<Props> = (props: Props) => {
  const spelling = props.match.params.spelling as string

  return (
    <Background>
      <TextTitle>意味の登録</TextTitle>
      <Text>登録してみんなに教えてあげよう</Text>
      <MeaningRegistration spelling={spelling} />
    </Background>
  )
}

const Background = styled.div`
  text-align: center;
  color: white;
`

const TextTitle = styled.h2`
  margin-bottom: 10px;
`

const Text = styled.div`
  font-weight: bold;
  margin-bottom: 1rem;
`

export default NewMeaningPage
