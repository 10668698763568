import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { WordLinkButtonList } from '.'
import { IWord } from '../../reducks/words/reducers'
import Button from '@material-ui/core/Button'

type Props = {
  words: IWord[]
  initDisplayNum: number
}

const PopularWords: React.FC<Props> = (props: Props) => {
  const [displayNum, setDisplayNum] = useState(props.initDisplayNum)
  const [hiddenMoreButton, setHiddenMoreButton] = useState(false)
  const handleMoreButton = useCallback(() => {
    const willDisplayNum = displayNum + 10
    setDisplayNum(willDisplayNum)
    if (willDisplayNum >= props.words.length) {
      setHiddenMoreButton(true)
    }
  }, [displayNum, props.words.length])

  return (
    <Background>
      <TextTitle>人気ワード</TextTitle>
      <Text>よく見られている言葉だよ</Text>
      <Text>みんな知ってる？</Text>
      <WordLinkButtonList words={props.words.slice(0, displayNum)} />
      {!hiddenMoreButton && (
        <ButtonOuter>
          <StyledButton onClick={handleMoreButton}>もっと見る</StyledButton>
        </ButtonOuter>
      )}
    </Background>
  )
}

const Background = styled.div`
  text-align: center;
  margin-top: 25px;
  padding-top: 1px;
  background: #404040;
`

const TextTitle = styled.h2`
  color: white;
  margin-bottom: 10px;
`

const Text = styled.div`
  color: white;
  font-weight: bold;
`

const ButtonOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70px;
`

const StyledButton = styled(Button)`
  color: white;
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
`

export default PopularWords
