import React from 'react'
import { useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'
import * as commonSelectors from '../../reducks/common/selectors'
import { StoreState } from '../../store/store'

const Loading: React.FC = () => {
  const state = useSelector<StoreState, StoreState>((state) => state)
  const isLoading: boolean = commonSelectors.isLoading(state)

  return (
    <div>
      {isLoading && (
        <BoxBg>
          <Box>
            <CircularProgress />
          </Box>
        </BoxBg>
      )}
    </div>
  )
}

const BoxBg = styled.div`
  z-index: 10;
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`

const Box = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`

export default Loading
