import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { IconTextLinkButton } from '../UIKit'
import HomeIcon from '@material-ui/icons/Home'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import CreateIcon from '@material-ui/icons/Create'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'

const Footer: React.FC = () => {
  const dispatch = useDispatch()

  return (
    <Background>
      <Logo src="/assets/logo.svg" alt="logo" onClick={() => dispatch(push('/'))} />
      <div>
        <IconTextLinkButton svgIcon={HomeIcon} text="トップページ" path="/" />
        <IconTextLinkButton svgIcon={ImportContactsIcon} text="言葉一覧" path="/initial_search" />
        <IconTextLinkButton svgIcon={CreateIcon} text="新しい言葉の登録" path="/new_word" />
        <IconTextLinkButton
          svgIcon={MailOutlineIcon}
          text="運営への要望"
          path="https://docs.google.com/forms/d/e/1FAIpQLScQ0PD3_jBvlfg4Cndx-tIAkjf3pgod1WQA_gR1kyvnv74Y1g/viewform?usp=sf_link"
        />
      </div>
      <Spacer height={24} />
      <UnderArea>
        <StyledAnchor
          href="https://www.notion.so/futo/82fd7049cd30486693bf18e97e91433a"
          target="_blank"
          rel="noopener noreferrer"
        >
          利用規約
        </StyledAnchor>
        <StyledAnchor
          href="https://www.notion.so/futo/b6f2201f1ff6472bb3c8db5fbde31d69"
          target="_blank"
          rel="noopener noreferrer"
        >
          プライバシーポリシー
        </StyledAnchor>
      </UnderArea>
    </Background>
  )
}

const Background = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 390px;
  background-color: #212121;
`

const Logo = styled.img`
  margin: 25px 0 15px 0;
  cursor: pointer;
`

const UnderArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
`

const StyledAnchor = styled.a`
  font-size: 13px;
  font-weight: bold;
  color: white;
  margin: 0 1rem 0 1rem;
`

const Spacer = styled.div<{ height: number }>`
  width: 100%;
  margin-bottom: ${({ height }) => height}px;
`

export default Footer
