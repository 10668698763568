import { Action } from 'redux'

export enum ActionTypes {
  START_LOAD = 'START_LOAD',
  FINISH_LOAD = 'FINISH_LOAD',
  FINISH_INIT_LOAD = 'FINISH_INIT_LOAD',
}

export const startLoadAction = (): IStartLoadAction => {
  return { type: ActionTypes.START_LOAD }
}

export const finishLoadAction = (): IFinishLoadAction => {
  return { type: ActionTypes.FINISH_LOAD }
}

export const finishInitLoadAction = (): IFinishInitLoadAction => {
  return { type: ActionTypes.FINISH_INIT_LOAD }
}

interface IStartLoadAction extends Action {
  type: ActionTypes.START_LOAD
}

interface IFinishLoadAction extends Action {
  type: ActionTypes.FINISH_LOAD
}

interface IFinishInitLoadAction extends Action {
  type: ActionTypes.FINISH_INIT_LOAD
}

export type CommonActions = IStartLoadAction | IFinishLoadAction | IFinishInitLoadAction
