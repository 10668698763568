import React from 'react'
import styled from 'styled-components'
import { InitialButton } from '.'
import { initials } from '../../lib/utils'

const InitialButtonList: React.FC = () => {
  return (
    <Container>
      {initials.map((initial) => (
        <InitialButton key={'initialButtonList' + initial} initial={initial} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 12px;
`

export default InitialButtonList
