import React from 'react'
import { Switch, Route } from 'react-router'
import {
  TopPage,
  InitialSearchPage,
  WordPage,
  MeaningPage,
  NewWordPage,
  NewMeaningPage,
  SearchResultPage,
} from './Scenes'
import { Loading, FlashMessages, ScrollToTop, Header, Footer } from './shared'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { store, history } from '../store/store'

const Router: React.FC = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Loading />
        <FlashMessages />
        <Header />
        <ScrollToTop />
        <Switch>
          <Route exact path="/initial_search" component={InitialSearchPage} />
          <Route exact path="/word/:spelling" component={WordPage} />
          <Route exact path="/word/:spelling/meaning/:meaningId" component={MeaningPage} />
          <Route exact path="/new_word" component={NewWordPage} />
          <Route exact path="/word/:spelling/new_meaning" component={NewMeaningPage} />
          <Route exact path="/search_result/:spelling_parts" component={SearchResultPage} />
          <Route path="*" component={TopPage} />
        </Switch>
        <Footer />
      </ConnectedRouter>
    </Provider>
  )
}

export default Router
