import { ActionTypes, MeaningActions } from './actions'

const defaultState: IMeaningState = { list: [], spelling: '' }

const meaningReducer = (state = defaultState, action: MeaningActions): IMeaningState => {
  switch (action.type) {
    case ActionTypes.LOAD_MEANINGS:
      // リストの内容を全て更新する
      return {
        ...state,
        list: action.payload.meanings,
        spelling: action.payload.spelling,
      }
    case ActionTypes.ADD_FAVORITE:
      return { ...state }
    default:
      return state
  }
}

export interface IMeaning {
  id: string
  content: string
  spelling: string
  favorite: number
  updated_at: Date
  created_at: Date
}

export interface IMeaningState {
  list: IMeaning[]
  spelling: string
}

export default meaningReducer
