import React from 'react'
import styled from 'styled-components'
import { MeaningButton } from '.'
import _ from 'lodash'
import { IMeaning } from '../../reducks/meanings/reducers'

type Props = {
  spelling: string
  meanings: IMeaning[]
  withoutIds?: string[]
}

const MeaningButtonList: React.FC<Props> = (props: Props) => {
  const withoutIds = props.withoutIds || []
  const sievedMeanings = _.reject(props.meanings, (meaning) => _.includes(withoutIds, meaning.id))
  // いいね数でソート
  const sortedMeanings = sievedMeanings.sort((m1, m2) => m2.favorite - m1.favorite)

  return (
    <>
      {sortedMeanings.length > 0 && (
        <MeaningBackground>
          {sortedMeanings.map((meaning, index) => {
            return <MeaningButton spelling={props.spelling} meaning={meaning} key={index} />
          })}
        </MeaningBackground>
      )}
    </>
  )
}

const MeaningBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 10px 0;
  background: #404040;
`

export default MeaningButtonList
