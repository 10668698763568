import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import styled from 'styled-components'
import { InitialButtonList, SearchTextArea, EllipseButton } from '../UIKit'
import { loadInitWords } from '../../reducks/words/operations'
import * as wordsSelectors from '../../reducks/words/selectors'
import * as commonSelectors from '../../reducks/common/selectors'
import { StoreState } from '../../store/store'
import LatestWords from '../UIKit/LatestWords'

const TopPage: React.FC = () => {
  const dispatch = useDispatch()
  const state = useSelector<StoreState, StoreState>((state) => state)
  const shouldInitLoad = commonSelectors.shouldInitLoad(state)
  const latestWords = wordsSelectors.wordsOrderByMeaningUpdatedAt(state)
  // const popularWords = wordsSelectors.wordsOrderByPageview(state)

  useEffect(() => {
    if (shouldInitLoad) {
      dispatch(loadInitWords())
    }
    return () => {}
  }, [shouldInitLoad, dispatch])

  return (
    <Background>
      <img src="/assets/top_image.png" alt="top_image" />
      <SearchTextArea />
      <TextTitle>言語一覧</TextTitle>
      <Text>読み方の頭文字から探せるよ</Text>
      <InitialButtonList />
      <LatestWords words={latestWords} initDisplayNum={5} />
      {/* <PopularWords words={popularWords} initDisplayNum={5} /> */}
      <TextTitle>新しい言葉の登録</TextTitle>
      <Text>違う意味や言葉を登録できるよ</Text>
      <Spacer height={24} />
      <EllipseButton text="教えてあげる" onClick={() => dispatch(push('new_word'))} />
      <Spacer height={36} />
    </Background>
  )
}

const Background = styled.div`
  text-align: center;
  color: white;
`

const TextTitle = styled.h2`
  margin: 30px 0 10px 0;
`

const Text = styled.div`
  font-weight: bold;
`

const Spacer = styled.div<{ height: number }>`
  width: 100%;
  margin-bottom: ${({ height }) => height}px;
`

export default TopPage
