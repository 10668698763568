import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as serviceWorker from './serviceWorker'
// styled-componentのスタイルをMaterial-UIより優先させるために使用
// https://qiita.com/Ouvill/items/c6761c32d31ffb11e114
import { StylesProvider } from '@material-ui/styles'
import Router from './components/Router'
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import theme from './lib/theme'

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <MaterialThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <Router />
        </StyledThemeProvider>
      </MaterialThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.unregister()
