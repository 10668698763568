import { Action } from 'redux'

export enum ActionTypes {
  LOAD_NGWORDS = 'LOAD_NGWORDS',
}

export const loadNgwordsAction = (ngwords: string[]): ILoadNgwordsAction => {
  return {
    type: ActionTypes.LOAD_NGWORDS,
    payload: {
      ngwords,
    },
  }
}

interface ILoadNgwordsAction extends Action {
  type: ActionTypes.LOAD_NGWORDS
  payload: {
    ngwords: string[]
  }
}

export type NgwordActions = ILoadNgwordsAction
