import React from 'react'
import styled from 'styled-components'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { SvgIconTypeMap } from '@material-ui/core'

type Props = {
  svgIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  text: string
}

const IconText: React.FC<Props> = (props: Props) => {
  return (
    <Background>
      <props.svgIcon color="primary" style={{ fontSize: '25px', margin: '3px 10px 3px 3px' }} />
      <Text>{props.text}</Text>
    </Background>
  )
}

const Background = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`

const Text = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: white;
`

export default IconText
