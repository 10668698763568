import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import styled from 'styled-components'
import { WordLinkButtonList } from '.'
import { IWord } from '../../reducks/words/reducers'
import { EllipseButton } from '.'

type Props = {
  words: IWord[]
  spellingParts: string
}

const SearchResultWords: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()

  return (
    <Background>
      <Title>「{props.spellingParts}」の検索結果</Title>
      {props.words.length > 0 ? (
        <WordLinkButtonList words={props.words} />
      ) : (
        <ResultNoneTextOuter>
          <ResultNoneText>が見つかりませんでした...</ResultNoneText>
          <EllipseButton
            text="検索キーワードを登録する"
            onClick={() => dispatch(push('/new_word?spelling=' + props.spellingParts))}
          />
        </ResultNoneTextOuter>
      )}
    </Background>
  )
}

const Background = styled.div`
  text-align: center;
  margin-top: 25px;
  padding-top: 1px;
  background: #404040;
`

const Title = styled.h2`
  color: white;
  margin-bottom: 0px;
`

const ResultNoneTextOuter = styled.div`
  height: 150px;
`

const ResultNoneText = styled.p`
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 0px;
`

export default SearchResultWords
