let firebaseConfig = {}

if (process.env.REACT_APP_ENV === 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyBcGjDzwhJ8p2IICtkyrNijmnlkcvvdBFU',
    authDomain: 'jiten-7e3d4.firebaseapp.com',
    databaseURL: 'https://jiten-7e3d4.firebaseio.com',
    projectId: 'jiten-7e3d4',
    storageBucket: 'jiten-7e3d4.appspot.com',
    messagingSenderId: '312472306292',
    appId: '1:312472306292:web:f8f48edfce97558b0c4721',
    measurementId: 'G-1F4V4MFDBG',
    timestampsInSnapshots: true,
  }
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyCd8l_iQwItYJEXoR-osfGbrLAgzf0z2I0',
    authDomain: 'jiten-development-a757d.firebaseapp.com',
    databaseURL: 'https://jiten-development-a757d.firebaseio.com',
    projectId: 'jiten-development-a757d',
    storageBucket: 'jiten-development-a757d.appspot.com',
    messagingSenderId: '904652851302',
    appId: '1:904652851302:web:9828aa49d5a3921a17c5a7',
    measurementId: 'G-FNPRNY60ZN',
  }
}

export default firebaseConfig
